import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";

import { Field, getFormValues, reduxForm } from "redux-form";

import FieldTextarea from "../../../components/reduxForm/FieldTextarea";
import Button, { ButtonsGroup } from "../../../components/Button";

import styles from "./styles.module.css";

const MedicationRequestUpdateForm = ({
  handleSubmit,
  onSubmit = () => {},
  onCancel,
  submitting,
  disabled
}) => (
  <form onSubmit={handleSubmit(onSubmit)}>
    <div className={styles.input}>
      <Field
        name="block_reason"
        component={FieldTextarea}
        disabled={disabled}
        placeholder="Вкажіть причину"
      />
    </div>
    <ButtonsGroup>
      <Button onClick={onCancel} theme="border">
        Скасувати
      </Button>
      <Button type="submit" disabled={submitting}>
        {submitting ? "Збереження..." : "Підтвердити"}
      </Button>
    </ButtonsGroup>
  </form>
);

export default compose(
  reduxForm({
    form: "medical-request-update-form"
  }),
  connect((state) => ({
    values: getFormValues("medical-request-update-form")(state)
  }))
)(MedicationRequestUpdateForm);
