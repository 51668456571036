import _defineProperty from "/app/node_modules/.pnpm/@babel+runtime@7.0.0/node_modules/@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var getDefinitions = function getDefinitions(_ref) {
  var data = _ref.data,
    _ref$keyExtractor = _ref.keyExtractor,
    keyExtractor = _ref$keyExtractor === void 0 ? function (item, index) {
      return index;
    } : _ref$keyExtractor,
    renderLabel = _ref.renderLabel,
    renderItem = _ref.renderItem;
  return data.reduce(function (_ref2, item, index) {
    var labels = _ref2.labels,
      items = _ref2.items;
    var key = keyExtractor(item, index);
    return {
      labels: _objectSpread(_objectSpread({}, labels), {}, _defineProperty({}, key, renderLabel(item))),
      items: _objectSpread(_objectSpread({}, items), {}, _defineProperty({}, key, renderItem(item)))
    };
  }, {
    labels: {},
    items: {}
  });
};
export default getDefinitions;