import _defineProperty from "/app/node_modules/.pnpm/@babel+runtime@7.0.0/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _objectWithoutProperties from "/app/node_modules/.pnpm/@babel+runtime@7.0.0/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties";
var _excluded = ["prop", "key"];
import { util } from "styled-system";
import PropTypes from "prop-types";
import mixed from "./mixed";
var boolean = function boolean(_ref) {
  var prop = _ref.prop,
    key = _ref.key,
    styles = _objectWithoutProperties(_ref, _excluded);
  var fn = function fn(props) {
    return mixed(props[prop] ? util.get(props.theme, key) || styles : null);
  };
  fn.propTypes = _defineProperty({}, prop, PropTypes.bool);
  return fn;
};
export default boolean;