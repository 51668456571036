var getCookie = function getCookie(name) {
  var nameLenPlus = name.length + 1;
  return document.cookie.split(";").map(function (c) {
    return c.trim();
  }).filter(function (cookie) {
    return cookie.substring(0, nameLenPlus) === "".concat(name, "=");
  }).map(function (cookie) {
    return decodeURIComponent(cookie.substring(nameLenPlus));
  })[0] || null;
};
export default getCookie;