import { handleAction, combineActions } from "redux-actions";
import { normalize } from "normalizr";

import { createUrl } from "../helpers/url";
import { medication_request } from "../schemas";
import { invoke } from "./api";

export const fetchMedicationRequests = (options) =>
  invoke({
    endpoint: createUrl(`/api/admin/medication_requests`, options),
    method: "GET",
    headers: {
      "content-type": "application/json"
    },
    types: [
      "medication_requests/FETCH_LIST_REQUEST",
      {
        type: "medication_requests/FETCH_LIST_SUCCESS",
        payload: (action, state, res) =>
          res
            .clone()
            .json()
            .then((json) => normalize(json.data, [medication_request])),
        meta: (action, state, res) =>
          res
            .clone()
            .json()
            .then((json) => json.paging)
      },
      "medication_requests/FETCH_LIST_FAILURE"
    ]
  });

export const fetchMedicationRequest = (id) =>
  invoke({
    endpoint: createUrl(`/api/admin/medication_requests/${id}`),
    method: "GET",
    headers: {
      "content-type": "application/json"
    },
    types: [
      "medication_requests/FETCH_DETAILS_REQUEST",
      {
        type: "medication_requests/FETCH_DETAILS_SUCCESS",
        payload: (action, state, res) =>
          res.json().then((json) => normalize(json.data, medication_request))
      },
      "medication_requests/FETCH_DETAILS_FAILURE"
    ]
  });

export const blockMedicationRequest = (id, body) =>
  invoke({
    endpoint: createUrl(`/api/medication_requests/${id}/actions/block`),
    method: "PATCH",
    headers: {
      "content-type": "application/json"
    },
    types: [
      "medication_requests/BLOCK_REQUEST",
      {
        type: "medication_requests/BLOCK_SUCCESS",
        payload: (action, state, res) =>
          res.json().then((json) => normalize(json.data, medication_request))
      },
      {
        type: "medication_requests/BLOCK_FAILURE",
        payload: (action, state, res) => res.json().then((json) => json.error)
      }
    ],
    body
  });

export const unblockMedicationRequest = (id) =>
  invoke({
    endpoint: createUrl(`/api/medication_requests/${id}/actions/unblock`),
    method: "PATCH",
    headers: {
      "content-type": "application/json"
    },
    types: [
      "medication_requests/UNBLOCK_REQUEST",
      {
        type: "medication_requests/UNBLOCK_SUCCESS",
        payload: (action, state, res) =>
          res.json().then((json) => normalize(json.data, medication_request))
      },
      {
        type: "medication_requests/UNBLOCK_FAILURE",
        payload: (action, state, res) => res.json().then((json) => json.error)
      }
    ]
  });

export default handleAction(
  combineActions(
    "medication_requests/FETCH_LIST_SUCCESS",
    "medication_requests/FETCH_DETAILS_SUCCESS",
    "medication_requests/BLOCK_SUCCESS",
    "medication_requests/UNBLOCK_SUCCESS"
  ),
  (state, action) => ({
    ...state,
    ...action.payload.entities.medication_requests,
    ...action.meta
  }),
  {}
);
