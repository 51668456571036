import _slicedToArray from "/app/node_modules/.pnpm/@babel+runtime@7.0.0/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import _defineProperty from "/app/node_modules/.pnpm/@babel+runtime@7.0.0/node_modules/@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var filterPropertiesByKey = function filterPropertiesByKey(data, filter) {
  var slug = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : "";
  var filterFn = function filterFn(key, value, filter, slug) {
    if (filter.includes(slug) && lastInSlug(slug) === key) {
      return {};
    }
    if (Array.isArray(value)) {
      return _defineProperty({}, key, arrFilter(value, filter, slug));
    }
    if (typeof value === "object" && value !== null) {
      return _defineProperty({}, key, objFilter(value, filter, slug));
    }
    return _defineProperty({}, key, value);
  };
  var lastInSlug = function lastInSlug(str) {
    var slugArr = str.split(".");
    return slugArr[slugArr.length - 1];
  };
  var arrFilter = function arrFilter(data, filter, slug) {
    return data.map(function (el) {
      return filterPropertiesByKey(el, filter, slug);
    });
  };
  var objFilter = function objFilter(data, filter, slug) {
    return Object.entries(data).reduce(function (prev, _ref4) {
      var _ref5 = _slicedToArray(_ref4, 2),
        k = _ref5[0],
        v = _ref5[1];
      return _objectSpread(_objectSpread({}, prev), filterFn(k, v, filter, slug ? "".concat(slug, ".").concat(k) : k));
    }, {});
  };
  if (typeof data === "object" && data !== null) return objFilter(data, filter, slug);
  return data;
};
export default filterPropertiesByKey;