import _defineProperty from "/app/node_modules/.pnpm/@babel+runtime@7.0.0/node_modules/@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { FORM_ERROR } from "final-form";
import set from "lodash/set";
import normalizeInputErrors from "./normalizeInputErrors";
var normalizeErrors = function normalizeErrors(errors, prefix) {
  var normalizedErrors = errors.reduce(function (acc, _ref) {
    var message = _ref.message,
      _ref$extensions = _ref.extensions,
      code = _ref$extensions.code,
      exception = _ref$extensions.exception;
    var error = exception && exception.inputErrors ? normalizeInputErrors(exception.inputErrors, prefix) : _defineProperty({}, FORM_ERROR, message);
    return _objectSpread(_objectSpread({}, acc), error);
  }, {});
  return prefix ? set({}, prefix, normalizedErrors) : normalizedErrors;
};
export default normalizeErrors;