import fromPairs from "lodash/fromPairs";
import createFlags from "flag";

import env from "./env";

const features = env.REACT_APP_FEATURES
  ? fromPairs(env.REACT_APP_FEATURES.split(",").map((f) => [f, true]))
  : {};

const flags = {
  features,
  computed: {}
};

const { FlagsProvider, Flag, useFlag, useFlags } = createFlags();

export { FlagsProvider, Flag, useFlag, useFlags };

export default flags;
