import _slicedToArray from "/app/node_modules/.pnpm/@babel+runtime@7.0.0/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import _defineProperty from "/app/node_modules/.pnpm/@babel+runtime@7.0.0/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _objectWithoutProperties from "/app/node_modules/.pnpm/@babel+runtime@7.0.0/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties";
var _excluded = ["cssFunction"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { style, util, compose } from "styled-system";
export var gradientStyle = function gradientStyle(_ref) {
  var cssFunction = _ref.cssFunction,
    options = _objectWithoutProperties(_ref, _excluded);
  return function (props) {
    var colors = util.get(props.theme, "colors") || {};
    var transformValue = transformGradient(cssFunction, colors);
    return style(_objectSpread(_objectSpread({}, options), {}, {
      transformValue: transformValue
    }))(props);
  };
};
var transformGradient = function transformGradient(cssFunction, colors) {
  return function (value) {
    var args = Array.isArray(value) ? value.map(transformColorStop(colors)).join(", ") : value;
    return "".concat(cssFunction, "(").concat(args, ")");
  };
};
var transformColorStop = function transformColorStop(colors) {
  return function (colorStop) {
    var getColor = function getColor(value) {
      return util.get(colors, value) || value;
    };
    if (Array.isArray(colorStop)) {
      var _colorStop = _slicedToArray(colorStop, 2),
        color = _colorStop[0],
        length = _colorStop[1];
      return [getColor(color), util.px(length)].join(" ");
    } else {
      return getColor(colorStop);
    }
  };
};
export var linearGradient = gradientStyle({
  prop: "linearGradient",
  cssProperty: "backgroundImage",
  cssFunction: "linear-gradient",
  key: "gradients"
});
export var radialGradient = gradientStyle({
  prop: "radialGradient",
  cssProperty: "backgroundImage",
  cssFunction: "radial-gradient",
  key: "gradients"
});
export var repeatingLinearGradient = gradientStyle({
  prop: "repeatingLinearGradient",
  cssProperty: "backgroundImage",
  cssFunction: "repeating-linear-gradient",
  key: "gradients"
});
export var repeatingRadialGradient = gradientStyle({
  prop: "repeatingRadialGradient",
  cssProperty: "backgroundImage",
  cssFunction: "repeating-radial-gradient",
  key: "gradients"
});
export default compose(linearGradient, radialGradient, repeatingLinearGradient, repeatingRadialGradient);