import _defineProperty from "/app/node_modules/.pnpm/@babel+runtime@7.0.0/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _slicedToArray from "/app/node_modules/.pnpm/@babel+runtime@7.0.0/node_modules/@babel/runtime/helpers/esm/slicedToArray";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var pickProps = function pickProps(object, whitelist) {
  var filterFn = Array.isArray(whitelist) ? function (key) {
    return whitelist.includes(key);
  } : whitelist;
  return Object.entries(object).reduce(function (_ref, _ref2) {
    var _ref3 = _slicedToArray(_ref, 2),
      selected = _ref3[0],
      rejected = _ref3[1];
    var _ref4 = _slicedToArray(_ref2, 2),
      key = _ref4[0],
      value = _ref4[1];
    return filterFn(key) ? [_objectSpread(_objectSpread({}, selected), {}, _defineProperty({}, key, value)), rejected] : [selected, _objectSpread(_objectSpread({}, rejected), {}, _defineProperty({}, key, value))];
  }, [{}, {}]);
};
export default pickProps;